'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '../../node_modules/@material-ui/core/Typography';
import CircularProgress from '../../node_modules/@material-ui/core/CircularProgress';
import indigo from '@material-ui/core/colors/indigo';
import userAction from '../actions/userAction';
import userStore from '../stores/userStore';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 14,
        height: `calc(100vh)`,
        //background:`url('/img/bg_1.jpg') no-repeat fixed center`,
        backgroundSize: `cover`,
    },
    loginBox: {
        padding: theme.spacing.unit * 3
    },
    paper: {
        width: 250,
        borderRadius: theme.spacing.unit * 3,
        backgroundColor: `rgba(255,255,255,0.8)`
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: 0,
        marginRight: 0,
        width: 200,
    },
    textFieldPwd: {
        marginBottom: 0,
    },

    wrapper: {
        marginTop: 40,
        width: "100%",
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: indigo[500],
    },
    signInBtn: {
        width: "100%"
    },

    progress: {
        maringTop: 40
    }
});



class Login extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            email: "",
            password: "",
            warningMsg: userStore.getState().warningMsg,
            processing: userStore.getState().processing,
            message: userStore.getState().message,
            isUserLogin: userStore.getState().isUserLogin,
        };
    }
    componentDidMount() {
        userStore.listen(this.onChange);
        if (this.state.isUserLogin) {
            this.props.history.push('/fnb/dashboard');
        }
    }
    componentWillUnmount() {
        userStore.unlisten(this.onChange);
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevState.isUserLogin && this.state.isUserLogin != prevState.isUserLogin) {
            this.props.history.push('/fnb/dashboard');
        }
    }
    onChange = (state) => {
        this.setState({
            isUserLogin: state.isUserLogin,
            processing: state.processing,
            warningMsg: state.warningMsg,
            message: state.message,
        });
    }
    handleKeyPress = (e) => {
        if (e.key == 'Enter') {
            this.handleClick();
        }
    }
    handleClick = (e) => {
        if (this.state.email == "" || this.state.password == "") {
            return this.setState({ warningMsg: "Email and Password cannot be empty" });
        } else {
            //gg to next page
            if (this.state.processing) return;

            userAction.processing(true);
            userAction.userLogin(this.state.email, this.state.password);
        }
    }
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
        if (this.state.warningMsg) {
            userAction.warningMsg(false);
        }
    };

    render() {
        const { classes } = this.props;
        const { email, password, warningMsg, processing, message } = this.state;
        return (
            <Grid container className={classes.root} justify="center" spacing={0}>
                <Grid item>
                    <Paper className={classes.paper}>
                        <Grid className={classes.loginBox} item>
                            
                            <div style={{textAlign:'center'}}>
                                <img src='/img/logo.png' width="80%"/>
                            </div>
                            
                            <Typography variant="caption" color="error">
                                {message}
                            </Typography>
                            <form className={classes.container} noValidate autoComplete="off">
                                <TextField
                                    id="email"
                                    error={warningMsg && email == ""}
                                    helperText={warningMsg && email == "" ? "Email is required" : ""}
                                    label="Email"
                                    className={classes.textField}
                                    value={email}
                                    type="email"
                                    onChange={this.handleChange('email')}
                                    margin="normal"
                                />
                                <TextField
                                    id="password"
                                    error={warningMsg && password == ""}
                                    helperText={warningMsg && password == "" ? "Password is required" : ""}
                                    label="Password"
                                    type="password"
                                    className={classNames(classes.textField, classes.textFieldPwd)}
                                    value={password}
                                    onChange={this.handleChange('password')}
                                    onKeyPress={this.handleKeyPress}
                                    margin="normal"
                                />
                                <div className={classes.wrapper}>
                                    <Button className={classes.signInBtn} disabled={processing}
                                        variant="contained" color="primary" onClick={this.handleClick}>
                                        Sign In
                                    </Button>
                                    {processing && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </form>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);